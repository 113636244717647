import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" />
    <div style={{
      maxWidth: '650px',
      margin: '0px auto 10rem auto',
      padding: '0 1rem'
    }}>
      <Title mdxType="Title">Starswap protocol disclaimer
      </Title>
      <p>{`Starswap is a decentralized peer-to-peer protocol that people can use to create liquidity and trade ERC-20 tokens. There are three versions of the Starswap protocol (v1, v2, and v3), each of which is made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on the Ethereum Blockchain. Your use of the Starswap protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the Starswap protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Starswap protocol, you should review the relevant documentation to make sure you understand how the Starswap protocol works. Additionally, just as you can access email email protocols such as SMTP through multiple email clients, you can access the Starswap protocol through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.`}</p>
      <p>{`AS DESCRIBED IN THE Starswap PROTOCOL LICENSES, THE Starswap PROTOCOL IS PROVIDED `}{`”`}{`AS IS`}{`”`}{`, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although Universal Navigation Inc. d/b/a/ `}{`”`}{`Starswap Labs`}{`”`}{` ( `}<strong parentName="p">{`”`}{`Starswap Labs`}{`”`}</strong>{` ) developed much of the initial code for the Starswap protocol, it does not provide, own, or control the Starswap protocol, which is run by smart contracts deployed on the Ethereum blockchain. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the UNI governance token. No developer or entity involved in creating the Starswap protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the Starswap protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      